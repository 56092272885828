
import * as localForage from "localForage";

function checkVisits(position) {
  localForage.getItem("visits").then((result) => {
    let res = result || [];
    if (res && res.length > 0) {
      res = res.reverse();
      //const i = res.findIndex((obj => obj.modal == false));
      const i = 0;
      const now = new Date();
      let sameDay = true;
      let elapsed = 0;
      
      if (res[i]) {
        
        //console.log('res', res[i])
        const start = new Date(res[i].ts);
        elapsed = (now.valueOf() - start.valueOf()) / 1000;
        sameDay = (
          start.getDate() === now.getDate()
          && start.getMonth() === now.getMonth()
          && start.getFullYear() === now.getFullYear()
        )
        //console.log('start.time', start)
        //console.log('end.time', now)
        console.log('elapsed', elapsed)
        //console.log('sameDay', sameDay)

        if (elapsed > 720 && res[i].modal == false) { // 2 hours
        //if (!sameDay) {
            
            let start = { lat: Number(position.coords.latitude), lng: Number(position.coords.longitude) };
            let end = { lat: Number(res[i].lat), lng: Number(res[i].lng) };
            //console.log('start.pos', start)
            let dist = Number(getDistanceBetweenPoints(start, end).toFixed(2));

            //if (dist > 0.5) {
              console.log('SHOW MODAL')
                res[i].modal = now;
                // $('#feedback').on('show.bs.modal', function (event) {
                //   $(this).find('iframe').attr('src', `https://surveys-eu.reputation.com/surveys3/run?tenantKey=1e505c5c648&templateId=6551faf09569b742b535c72c&locale=en_US&property-value-Mode=Website&locationCode=${res[i].rep}`)
                // });
                $('#feedback').modal('show');
                return localForage.setItem("visits", res);
           // }
          }

         
        } 
        
      } 
      
  })
}

function setVisit(data) {
  localForage.getItem("visits").then((res) => {
    res = res || [];
    if (res && res.length > 0) {
      res = res.reverse();
      const i = res.findIndex((obj => obj.id == data.id));
      const now = new Date();
      let sameDay = true;
      let elapsed = 0;

      if (res[i]) {

        const start = new Date(res[i].ts);
        elapsed = (now.valueOf() - start.valueOf()) / 1000;
        sameDay = (
          start.getDate() === now.getDate()
          && start.getMonth() === now.getMonth()
          && start.getFullYear() === now.getFullYear()
        )

        if (!sameDay) {
          res[i].visits++;
          res[i].ts = now;
          res[i].modal = false;
        }
   
      } else {
        res.push({ id: data.id,title: data.title, ts: data.ts, visits: 1, modal: false, lat: data.lat, lng: data.lng, rep: data.rep });
      }

    } else {
      res.push({ id: data.id, title: data.title, ts: data.ts, visits: 1, modal: false, lat: data.lat, lng: data.lng, rep: data.rep });
    }
    return localForage.setItem("visits", res);

  })

}

function sleep(ms) {
  // return new Promise(resolve => setTimeout(resolve, ms));

  return new Promise(function (resolve) {
    setTimeout(resolve, ms);
  });
}

let myPosition = new Promise(function (resolve, reject) {

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      //let lat = position.coords.latitude;
      //let lng = position.coords.longitude;
      
      resolve(position);
      reject();
    })
  } else {
    alert("Nooop");
  }
})

$(function () {
  
  let postID = $('.wifi-page').attr('data-pid');
  let postTitle = $('.wifi-page').attr('data-title');
  
  const urlParams = new URLSearchParams(window.location.search);
  const nasid = urlParams.get('nasid');

  console.log('postID', postID, 'postTitle', postTitle, 'nasid', nasid, )
  
  if (window.location.pathname != '/wifi/') {
    
    myPosition.then(
      function (position) {
        
        let _data = {
          title: "foo",
          body: "bar",
          userId: 1
        }
        
        let locationArr = [];
        let url = `${window.location.origin}/wp-json/moto/locations?per_page=999`
        fetch(url, {
          method: "GET",
          //body: JSON.stringify(_data),
          headers: { "Content-type": "application/json; charset=UTF-8" }
        })
        .then(response => response.json())
        .then(json => {
          locationArr = json;
          let distances = [];
          
          locationArr.forEach(location => {
            let start = { lat: Number(position.coords.latitude), lng: Number(position.coords.longitude) };
            let end = { lat: Number(location.acf.geo.latitude), lng: Number(location.acf.geo.longitude) };
            //console.log('start.pos', start)
            //console.log('end.pos', end, location.id, location.name)
            //console.log('location', location)
            distances.push({ id: location.id, distance: Number(getDistanceBetweenPoints(start, end).toFixed(2)), title: location.title.rendered, lat: end.lat, lng: end.lng, rep: location.acf.id.reputation })
          })
          //console.log('distances', distances)

            if (distances.length > 0) {

              let hasMin = distances.hasMin('distance');

              if (hasMin) {
                if (hasMin.distance <= 0.5) {
                  setVisit({ id: hasMin.id, ts: position.timestamp, title: hasMin.title, lat: hasMin.lat, lng: hasMin.lng, rep: hasMin.rep })
                }
              }
            }
            checkVisits(position)
          })
          
          .catch(err => console.log(err));
      },
      function (error) { }
    );

  }
  
  if ( $('body').hasClass('page-template-page-wifi') && nasid && postTitle !== 'Wifi') {
    console.log('wifi page');
    sleep(3000).then(function() {

      localForage.getItem("wifi-modal").then((res) => {
          res = res || [];
          const now = new Date();

          if (res && res.length > 0) {
              res = res.reverse();
              const i = res.findIndex((obj => obj.id == postID)); // find the index matching the postid
              let sameDay = true;
              let elapsed = 0;

              if (res[i]) {
                  console.log('found', 'ts', res[i].ts)
                  const start = new Date(res[i].ts);
                  elapsed = (now.valueOf() - start.valueOf()) / 1000;
                  sameDay = (
                      start.getDate() === now.getDate() &&
                      start.getMonth() === now.getMonth() &&
                      start.getFullYear() === now.getFullYear()
                  )

                  console.log('elapsed', elapsed)

                  if (elapsed > 0) {
                      $('#wifi').modal('show')
                      res[i].visits++;
                      res[i].ts = now;
                      localForage.setItem("wifi-modal", res);
                  }

              } else {
                  res.push({
                      id: postID,
                      ts: now,
                      visits: 1,
                      modal: false,
                  });
                  $('#wifi').modal('show');
                  localForage.setItem("wifi-modal", res);
              }

          } else {
              res.push({
                  id: postID,
                  ts: now,
                  visits: 1,
                  modal: false
              });
              $('#wifi').modal('show');
              localForage.setItem("wifi-modal", res);
          }

          //return localForage.getItem("wifi-modal");

      }).catch((err) => {
          console.log(err);
      });
    });


    $('#rate').on('click', function(e) {
        let id = $(this).attr('data-id');
        //e.preventDefault();
        $('#wifi').modal('hide');

        localForage.getItem("wifi-modal").then((res) => {
            res = res || [];
            if (res && res.length > 0) {
                res = res.reverse();
                const i = res.findIndex((obj => obj.id == id));

                if (res[i]) {
                    res[i].modal = true;
                    return localForage.setItem("wifi-modal", res);
                };
            };
        });
    })
  }
})


Array.prototype.hasMin = function (attrib) {
  return (this.length && this.reduce(function (prev, curr) {
    return prev[attrib] < curr[attrib] ? prev : curr;
  })) || null;
}

function getDistanceBetweenPoints(start, end, units) {
  let earthRadius = {
    miles: 3958.8,
    km: 6371
  }

  let R = earthRadius[units || 'miles']
  let lat1 = start.lat
  let lon1 = start.lng
  let lat2 = end.lat
  let lon2 = end.lng

  let dLat = toRad((lat2 - lat1))
  let dLon = toRad((lon2 - lon1))
  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  let d = R * c

  return d

}

function toRad(x) {
  return x * Math.PI / 180
}

function radians(n) {
  return n * (Math.PI / 180);
}

function degrees(n) {
  return n * (180 / Math.PI);
}