$(function () {
  // Make our login request to our proxy for auth0
  $("a.go-btn").on("click", function (event) {
    event.preventDefault();

    // Hide by default
    $("#auth-status").hide();

    /** Pass nonce for security */
    let nonce = $(this).attr("data-nonce");

    /** Login details */
    let username = $("#username").val();
    let password = $("#password").val();

    if (username == "" || password == "" || nonce == "") {
      $("#auth-status")
        .text("Invalid values supplied")
        .removeClass("text-success")
        .addClass("text-danger")
        .show();
      return;
    }

    // Make our login request to our proxy for auth0
    $.ajax({
      type: "post",
      dataType: "json",
      url: $(this).attr("href"),
      dataType: "json",
      data: {
        action: "auth_login",
        nonce: nonce,
        username,
        password,
      },
      success: function (response) {
        if (response.status === "error") {
          $("#auth-status")
            .text(response.data)
            .removeClass("text-success")
            .addClass("text-danger")
            .show();
        } else if (response.status === "success") {
          window.location = "/";
        }
      },
    });
  });

  /*
   * Updates user profile on Larevel and hubspot
   */
  $("a.update-profile-btn").on("click", function (event) {
    event.preventDefault();

    $("#profileHelp")
      .text("Your profile")
      .removeClass("text-danger")
      .addClass("text-muted");

    $("#phoneHelp")
      .text("Your contact number")
      .removeClass("text-danger")
      .addClass("text-muted");

    // Pass nonce for security
    let nonce = $(this).attr("data-nonce");

    /** profile type */
    let profile = $("#profile-type").val();

    /** phone no */
    let phone = $("#phone").val();

    let errors = false;

    if (profile == "") {
      $("#profileHelp")
        .text("Choose your profile")
        .removeClass("text-muted")
        .addClass("text-danger");
      errors = true;
    }

    if (phone == "") {
      $("#phoneHelp")
        .text("Enter valid contact no.")
        .removeClass("text-muted")
        .addClass("text-danger");
      errors = true;
    }

    if (errors) {
      $("#profile-status")
        .text("Invalid values supplied")
        .removeClass("text-success")
        .addClass("text-danger")
        .show();
      return;
    }

    // We have no errors, we are good to make registration request
    $.ajax({
      type: "post",
      dataType: "json",
      url: $(this).attr("href"),
      dataType: "json",
      data: {
        action: "update_profile",
        nonce: nonce,
        phone,
        profile,
      },
      success: function (response) {
        if (response.status === "error") {
          $("#profile-status")
            .text(response.data)
            .removeClass("alert-success")
            .removeClass("text-danger")
            .addClass("alert-danger")
            .show();
        } else if (response.status === "success") {
          $("#profile-status")
            .text(response.data)
            .removeClass("alert-danger")
            .removeClass("text-danger")
            .addClass("alert-success")
            .show();
        }
      },
    });
  });

  /*
   * Change logged in user password in auth0
   */
  $("a.update-password-btn").on("click", function (event) {
    event.preventDefault();

    /** Pass nonce for security */
    let secret = $(this).attr("data-secret");

    let errorsFound = false;

    let newPassword = $("#passwordNew").val();
    let newPasswordConfirm = $("#passwordConfirm").val();

    // Hide by default
    $("#password-status").hide();

    $("#passwordNewHelp")
      .text("New password.")
      .removeClass("text-danger")
      .addClass("text-muted");

    $("#passwordConfirmHelp")
      .text("Confirm new password.")
      .removeClass("text-danger")
      .addClass("text-muted");

    // Validate passwords match
    if (newPassword !== newPasswordConfirm) {
      errorsFound = true;
      $("#passwordHelp")
        .text("Password and Confirm password do not match.")
        .removeClass("text-muted")
        .addClass("text-danger");
      $("#passwordConfirmHelp")
        .text("Password and Confirm password do not match.")
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    // Validate password format
    let passMessage = verifyPassword(newPassword);
    if (passMessage !== "passed") {
      errorsFound = true;
      $("#passwordNewHelp")
        .text(passMessage)
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    if (errorsFound) {
      $("#password-status")
        .text("Invalid values supplied")
        .removeClass("text-success")
        .addClass("text-danger")
        .show();
      return;
    }

    // We have no errors, we are good to make registration request
    $.ajax({
      type: "post",
      dataType: "json",
      url: $(this).attr("href"),
      dataType: "json",
      data: {
        secret: secret,
        password: newPassword,
      },
      success: function (response) {
        if (response.status === "error") {
          $("#password-status")
            .text(response.data)
            .removeClass("alert-success")
            .removeClass("text-danger")
            .addClass("alert-danger")
            .show();
        } else if (response.status === "success") {
          $("#password-status")
            .html(response.data)
            .removeClass("alert-danger")
            .removeClass("text-danger")
            .addClass("alert-success")
            .show();
          pageRedirect();
          $(".logout-btn").show();
          $(".update-password-btn").hide();
        }
      },
    });
  });

  /*
   * Reset user password in auth0
   */
$("a.reset-password-btn").on("click", function (event) {
  event.preventDefault();

  /** Pass nonce for security */
  let secret = $(this).attr("data-secret");

  /** AuthID */
  let uid = $(this).attr("data-id");

  /** url */
  let api_url = $(this).attr("data-url");

  let errorsFound = false;

  let newPassword = $("#passwordNew").val();
  let newPasswordConfirm = $("#passwordConfirm").val();


  // Hide by default
  $("#password-status").hide();

  $("#passwordNewHelp")
    .text("New password.")
    .removeClass("text-danger")
    .addClass("text-muted");

  $("#passwordConfirmHelp")
    .text("Confirm new password.")
    .removeClass("text-danger")
    .addClass("text-muted");

  // Validate passwords match
  if (newPassword !== newPasswordConfirm) {
    errorsFound = true;
    $("#passwordNewHelp")
      .text("Password and Confirm password do not match.")
      .removeClass("text-muted")
      .addClass("text-danger");
    $("#passwordConfirmHelp")
      .text("Password and Confirm password do not match.")
      .removeClass("text-muted")
      .addClass("text-danger");
  }

  // Validate password format
  let passMessage = verifyPassword(newPassword);
  if (passMessage !== "passed") {
    errorsFound = true;
    $("#passwordNewHelp")
      .text(passMessage)
      .removeClass("text-muted")
      .addClass("text-danger");
  }

  if (errorsFound) {
    $("#password-status")
      .text("Invalid values supplied")
      .removeClass("text-success")
      .addClass("text-danger")
      .show();
    return;
  }

  
  $(this).addClass('loading');

  // We have no errors, we are good to make registration request
  $.ajax({
    type: "post",
    url: api_url,
    dataType: "json",
    data: {
      secret: secret,
      password: newPassword,
      auth_id: uid
    },
    success: function (response) {
      if (response.status === "error") {
        $("#password-status")
          .text(response.data)
          .removeClass("alert-success")
          .removeClass("text-danger")
          .addClass("alert-danger")
          .show();
      } else if (response.status === "success") {
        $("#password-status")
          .html(response.data)
          .removeClass("alert-danger")
          .removeClass("text-danger")
          .addClass("alert-success")
          .show();
        pageRedirect();
        $(".logout-btn").show();
        $(".reset-password-btn").hide();
        $("#hide-after-success").hide();
      }
    },
  });

  $(this).removeClass('loading');
});

  /*
   * Change user password in auth0
   */
  $("a.send-reset-password-btn").on("click", function (event) {
    event.preventDefault();

    /** Pass nonce for security */
    let secret = $(this).attr("data-secret");

    let email = $("#emailCurrent").val();

    // Hide by default
    $("#message-status").hide();

    if (!validateEmail(email)) {
      $("#message-status")
        .text("Invalid email supplied")
        .removeClass("text-success")
        .addClass("text-danger")
        .show();
      return;
    }

    $(this).addClass('loading');

    // We have no errors, we are good to make registration request
    $.ajax({
      type: "post",
      dataType: "json",
      url: $(this).attr("href"),
      dataType: "json",
      data: {
        secret: secret,
        email: email,
      },
      success: function (response) {
        if (response.status === "error") {
          $("#message-status")
            .text(response.data)
            .removeClass("alert-success")
            .removeClass("text-danger")
            .addClass("alert-danger")
            .show();
        } else if (response.status === "success") {
          $("#message-status")
            .html(response.data)
            .removeClass("alert-danger")
            .removeClass("text-danger")
            .addClass("alert-success")
            .show();
            $(".send-reset-password-btn").hide();
            $("#emailCurrentHelp").hide();
            $("#emailCurrent").hide();
        }
      },
    });

    $(this).addClass('remove');
  });

  /*
   * Updates user email on auth0 and hubspot
   */
  $("a.update-email-btn").on("click", function (event) {
    event.preventDefault();

    $("#email-status").hide();

    $("#emailNewHelp")
      .text("Your new email address.")
      .removeClass("text-danger")
      .addClass("text-muted");

    // Pass nonce for security
    let nonce = $(this).attr("data-nonce");

    /** new email */
    let newEmail = $("#emailNew").val();

    /** old email */
    let emailCurrent = $("#emailCurrent").val();

    let errors = false;

    if (!validateEmail(newEmail)) {
      $("#emailNewHelp")
        .text("Enter valid email address.")
        .removeClass("text-muted")
        .addClass("text-danger");
      errors = true;
    }

    if (emailCurrent === newEmail) {
      $("#emailNewHelp")
        .text("New email address is same.")
        .removeClass("text-muted")
        .addClass("text-danger");
      errors = true;
    }

    if (errors) {
      $("#email-status")
        .text("Invalid email supplied")
        .removeClass("text-success")
        .addClass("text-danger")
        .show();
      return;
    }

    // We have no errors, we are good to make registration request
    $.ajax({
      type: "post",
      dataType: "json",
      url: $(this).attr("href"),
      dataType: "json",
      data: {
        action: "update_email",
        nonce: nonce,
        newEmail,
      },
      success: function (response) {
        if (response.status === "error") {
          $("#email-status")
            .text(response.data)
            .removeClass("alert-success")
            .removeClass("text-danger")
            .addClass("alert-danger")
            .show();
        } else if (response.status === "success") {
          $("#email-status")
            .html(
              "<strong>Email updated.</strong><br/><br/>" +
                response.data +
                "<br/><br/>Email link has been sent."
            )
            .removeClass("alert-danger")
            .removeClass("text-danger")
            .addClass("alert-success")
            .show();
          pageRedirect();
          $(".logout-btn").show();
          $(".update-email-btn").hide();
        }
      },
    });
  });

  function pageRedirect() {
    let delay = 1000; // time in milliseconds
    let count = 6;

    setInterval(function () {
      count--;
      if (count <= 0) {
        window.location = "/";
      } else {
        $(".logout-btn").html("You will be logged out in " + count + " seconds.");
      }
      
    }, delay);
  }

  /*
   * Make social login request to our proxy for auth0
   */
  $("a.social-login").on("click", function (event) {
    // Pass nonce for security
    let nonce = $(this).attr("data-nonce");

    // Pass nonce for security
    let platform = $(this).attr("data-auth-socialid");

    // Reject invalid requests
    if (nonce == "") {
      return;
    }

    event.preventDefault();
    $.ajax({
      type: "post",
      dataType: "json",
      url: $(this).attr("href"),
      dataType: "json",
      data: {
        action: "auth_social",
        nonce: nonce,
        platform: platform,
      },
      success: function (response) {
        if (response.status === "error") {
          return false;
        } else if (response.status === "success") {
          window.location.href = response.data;
        }
      },
    });
  });

  /*
   * Register user using username and password provided
   */
  $("a.register-btn").on("click", function (event) {
    event.preventDefault();

    // Hide status default
    $("#registration-status").hide();
    // Hide inputs Errors
    $("#emailHelp")
      .text("Your email address for registration.")
      .removeClass("text-danger")
      .addClass("text-muted");
    $("#passwordHelp")
      .text("Your password for registration.")
      .removeClass("text-danger")
      .addClass("text-muted");
    $("#passwordConfirmHelp")
      .text("Confirm your password for registration.")
      .removeClass("text-danger")
      .addClass("text-muted");
    $("#passwordConfirmHelp").removeClass("text-danger").addClass("text-muted");
    $("#nameHelp")
      .text("Your fullname")
      .removeClass("text-danger")
      .addClass("text-muted");
    $('#agreeTCHelp')
    .text("")
    .removeClass("text-danger")
    .addClass("text-muted");

    /** Pass nonce for security */
    let nonce = $(this).attr("data-nonce");

    /** Register details */
    let email = $("#signup-email").val();
    let password = $("#signup-password").val();
    let passwordConfirmed = $("#signup-password-confirm").val();
    let name = $("#signup-name").val();
    let errorsFound = false;

    // Validate email
    if (!validateEmail(email)) {
      errorsFound = true;
      $("#emailHelp")
        .text("Invalid email address")
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    // Validate passwords match
    if (password !== passwordConfirmed) {
      errorsFound = true;
      $("#passwordHelp")
        .text("Password and Confirm password do not match.")
        .removeClass("text-muted")
        .addClass("text-danger");
      $("#passwordConfirmHelp")
        .text("Password and Confirm password do not match.")
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    // Validate password format
    let passMessage = verifyPassword(password);
    if (passMessage !== "passed") {
      errorsFound = true;
      $("#passwordHelp")
        .text(passMessage)
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    // Validate password confirm format
    passMessage = verifyPassword(passwordConfirmed);
    if (passMessage !== "passed") {
      errorsFound = true;
      $("#passwordConfirmHelp")
        .text(passMessage)
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    // Validate profile
    if (!verifyName(name)) {
      errorsFound = true;
      $("#nameHelp")
        .text("Invalid name provided.")
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    //validate terms and conditions
    if(!$('.agree-t-c').is(":checked")){
      $("#agreeTCHelp")
        .text("Check here to indicate that you have read and agree.")
        .removeClass("text-muted")
        .addClass("text-danger");
    }

    // If errors found, show error status and cancel registration submission
    if (errorsFound) {
      $("#registration-status")
        .text("Invalid registration values provided")
        .removeClass("alert-success")
        .addClass("alert-danger")
        .show();
      return;
    }

    // We have no errors, we are good to make registration request

    $.ajax({
      type: "post",
      dataType: "json",
      url: $(this).attr("href"),
      dataType: "json",
      data: {
        action: "auth_register",
        nonce: nonce,
        email,
        password,
        name,
      },
      success: function (response) {
        if (response.status === "error") {
          $("#registration-status")
            .text(response.data)
            .removeClass("alert-success")
            .addClass("alert-danger")
            .show();
        } else if (response.status === "success") {
          $("#registration-status")
            .text(response.data)
            .removeClass("alert-danger")
            .addClass("alert-success")
            .show();
          $(".registered").hide();
          $(".close-button").show();
        }
      },
    });
  });

  $("a.close-button").on("click", function (event) {
    $("#exampleModal").modal("toggle");
  });
});

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function verifyName(fullname) {
  if (fullname.length < 3) {
    return false;
  }

  let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
  if (!regName.test(fullname)) {
    return false;
  }

  return true;
}

function verifyPassword(pass) {
  // Check password for length
  if (pass.length < 8) {
    return "Password must be atleast 8 characters in length.";
  }
  // Check password for special characters
  let specialCharcaters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (!specialCharcaters.test(pass)) {
    return "Password must contain special characters (_!@#$%^&*-).";
  }

  return "passed";
}
