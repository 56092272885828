let map;
let infoWindows_arr = [];

console.log('MAP NOT INITD');

function initMap() {
  console.log('MAP INITD')
  map = new google.maps.Map(document.getElementById("gmap"), {
    zoom: 6.7,
    center: new google.maps.LatLng(53.56829377867329,  -2.232102155685425),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  });
}

function showHideFilterBtnsSection() {

  let showSection = false;
  jQuery.each(jQuery("#filterContent input[type=checkbox]"), function () {
    // Found the checkbox, show section
    if (jQuery(this).is(":checked")) {
      showSection = true;
      return;
    }
  });

  if (showSection) {
    jQuery(".nearu-page__filterContent__status p").show();
  } else {
    jQuery(".nearu-page__filterContent__status p").hide();
  }
}

jQuery(function () {

  if(jQuery('body').hasClass('post-type-archive-location') || jQuery('body').hasClass('page-template-template-snu') || jQuery('body').hasClass('page-template-template-careers-snu')) {

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    
    let latitude = params.lat;
    let longitude = params.lng;

    console.log('latitude: ' + latitude);

    servicesMainPageLoadServices(
      latitude,
      longitude,
      searchTerm,
      brand
  );
  }


  // Handles locations filters checkboxes. Fires ajax call to get filtered locations
  jQuery("#filterContent input[type=checkbox]").on('change', function () {
    // Get checkbox text , which is used for generating dynamic btns
    let filterValue = jQuery(this).next("label").text();

    // Filter checked, generate dynamic btn
    if (jQuery(this).is(":checked")) {
      //Dynamic btn
      let filterButton = `<button class="btn btn-secondary selected-filter" type="submit">${filterValue}<i class="close"></i></button>`;

      // Add to our container
      jQuery(".nearu-page__filterContent__status__selectedfilters").append(
        filterButton
      );
    } else {
      // Filter unchecked, find the btn in group of btns then remove it.
      jQuery(".selected-filter").each(function (i, btn) {
        if (jQuery(btn).text() == filterValue) {
          jQuery(btn).remove();
          //console.log("Removed btn: " + jQuery(btn).text());
        }
      });
    }

    // Show hide section based on checkboxes selected
    showHideFilterBtnsSection();

    // Get ajax results based on filters
    getLocationsBasedOnFilters();
  });

  // Remove filter btn
  jQuery("body").on("click", ".selected-filter", function () {
    let btnToRemove = jQuery(this).text();

    // Find the btn in group of btns to remove.
    jQuery(".selected-filter").each(function (i, btn) {
      if (jQuery(btn).text() == btnToRemove) {
        // Remobe btn
        jQuery(btn).remove();

        // Uncheck the checkbox for the btn we just removed
        jQuery.each(jQuery("#filterContent input[type=checkbox]"), function () {
          // Found the checkbox, remove it
          if (jQuery(this).next("label").text() == btnToRemove) {
            jQuery(this).prop("checked", false);

            // Show hide section based on checkboxes selected
            showHideFilterBtnsSection();

            // Get ajax results based on filters
            getLocationsBasedOnFilters();
          }
        });
      }
    });
  });

  jQuery(".view-results").on('click', function (e) {
    e.preventDefault();
    jQuery("#filterBtn").toggleClass("hamburger--spin is-active");
    jQuery("#filterContent").toggleClass("nearu-page__filterContent--show");

    // if (isMobile() === false) {
    //   jQuery('.breadcrumb > .inner').toggleClass('invisible');
    // }

    if (jQuery("#filterBtn").hasClass("is-active") === true) {
      jQuery("#filterBtn > .text").text("Close filter options");
    } else {
      jQuery("#filterBtn > .text").text("Filter options");
      return false;
    }
  });

  jQuery("#loadmore-btn").on('click', function (e) {
    e.preventDefault();

    let pageNo = jQuery(this).attr("data-pageno");
    let url = `/wp-admin/admin-ajax.php?action=load_more_blogs`;
    jQuery("img.loader").show();
    jQuery.ajax({
      type: "get",
      url: url,
      data: {
        pageno: pageNo,
      },
      success: function (response) {
        
        //increase pageno
        let nextPageNo = parseInt(pageNo) + 1;
        jQuery("#loadmore-btn").attr("data-pageno", nextPageNo);

        console.log(nextPageNo);
        jQuery(".news-page__newsblocks__block").last().after(response);
        jQuery("img.loader").hide();
        jQuery([document.documentElement, document.body]).animate(
          {
            scrollTop: jQuery(".scrollToMe").last().offset().top,
          },
          500
        );
      },
    });
  });

  /**
   * Get the users location on clicking the ok button in the location permission modal
   */
  jQuery(".geo-search-allow").on("click", function (el) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude.toFixed(4);
        let lng = position.coords.longitude.toFixed(4);
        // Now we have the users position, save it to the API...
        window.location.href = `/services?lat=${lat}&lng=${lng}`;
      });
    } else {
      alert("Please allow location lookup to use this feature.");
    }
  });

  jQuery("#searchButton").on("click", function (el) {
    if (navigator.geolocation) {
      jQuery("#searchButton").addClass("location-button__waiting");
      jQuery(".searchHero").val("Finding your position...");
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude.toFixed(4);
        let lng = position.coords.longitude.toFixed(4);
        // Now we have the users position, save it to the API...
        jQuery("#searchButton").removeClass("location-button__waiting");
        jQuery(".searchHero").val(lat + ", " + lng);
        window.location.href = `/services?lat=${lat}&lng=${lng}&ugeo=1`;
      });
    } else {
      alert("Please allow location lookup to use this feature.");
    }
  });

  jQuery("#brands-geo").on("click", function (el) {
    let brandId = encodeURIComponent(jQuery(this).attr("data-brand-id"));
    if (navigator.geolocation) {
      jQuery("#brands-geo").addClass("location-button__waiting");
      jQuery("#brands-page-location").val("Finding your position...");
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude.toFixed(4);
        let lng = position.coords.longitude.toFixed(4);
        // Now we have the users position, save it to the API...
        jQuery("#brands-geo").removeClass("location-button__waiting");
        jQuery("#brands-page-location").val(lat + ", " + lng);
        //jQuery("#locationConfirmbox").modal("toggle");
        window.location.href = `/services?brand=${brandId}&lat=${lat}&lng=${lng}&ugeo=1`;
      });
    } else {
      alert("Please allow location lookup to use this feature.");
    }
  });

  // Clear filters used for locations search
  jQuery(".clear-filters").click(function (e) {
    e.preventDefault();

    //Clear all the checkboxes
    jQuery.each(jQuery("#filterContent input[type=checkbox]"), function () {
      jQuery(this).prop("checked", false);
    });

    // Make Api query
    getLocationsBasedOnFilters();
  });

  // Attach a click event to search result items in the header 'find a services' search bar
  jQuery(document).on("click", ".search-services__result-item", function (el) {
    try {
      // get the google place id
      const gPlaceId = el.target.dataset.gPlaceId;
      let url = `/wp-admin/admin-ajax.php?action=google_geocode_get_location_data`;
      let brandID = jQuery(this).attr("data-brandid");

      // construct the locations search page URL
      jQuery.ajax({
        type: "get",
        url: url,
        data: {
          place_id: gPlaceId,
        },
        success: function (response) {
          
          let result = JSON.parse(response);
          const locationCoords = result.results[0].geometry.location;
          let searchResultsUrl = `/services/?lat=${locationCoords.lat}&lng=${locationCoords.lng}`;
          if (brandID !== 'false') {
            searchResultsUrl += `&brand=${brandID}`;
          }
          window.location.href = searchResultsUrl;
        },
      });
    } catch (ex) {
      console.log("Error getting place with ID. element:", el);
      console.error("Exceptions:", ex);
    }
  });

  jQuery("input#search, input#location").on("input", function (e) {
    let searchTerm = jQuery(this).val();
    let id = jQuery(this).attr("id");
    e.preventDefault();

    // We got some characters
    if (searchTerm.length > 0) {
      getLocationsBySearch(searchTerm, id);
    } else {
      // Clear search results as there are no charcaters
      if (id === "location") {
        jQuery(".search-results").html("");
      } else {
        jQuery(".search-widget__box__search-results").html("");
      }
    }
  });


  // Clear filters used for locations search
  jQuery(".route-go").on("click", function (e) {
    e.preventDefault();

    let start = jQuery("input#servicesfrom").val();
    let end = jQuery("input#servicesto").val();


    let url = `/journey-planner?start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`;

    // No need to route plan if any value is missing
    if (start !== "" && end !== "") {
      window.location.href = url;
    }
  });

  // Toggle user favorite brand
  jQuery("a.heart-btn-brands").click(function (e) {
    e.preventDefault();

    let url = jQuery(this).attr("attr-url");
    let brand = jQuery(this).attr("attr-brand");
    let favAction = jQuery(this).hasClass("active") ? "remove" : "add";
    //alert(brand);return;

    // Get our addresses from WP
    jQuery.ajax({
      type: "get",
      url: url,
      data: {
        favAction: favAction,
        brandName: brand,
      },
      success: function (response) {
        response = JSON.parse(response);
        if (response.status == "success") {
          let heart = document.getElementById("brand-" + brand);
          if (favAction == "remove") {
            heart.classList.remove("active");
          } else {
            heart.classList.add("active");
          }
        }
      },
    });
  });

  // Toggle user favorite
  jQuery("a.heart-btn").click(function (e) {
    e.preventDefault();

    let url = jQuery(this).attr("attr-url");
    let serviceId = jQuery(this).attr("attr-lid");
    let favAction = jQuery(this).hasClass("active") ? "remove" : "add";

    // Get our addresses from WP
    jQuery.ajax({
      type: "get",
      url: url,
      data: {
        favAction: favAction,
        serviceId: serviceId,
      },
      success: function (response) {
        response = JSON.parse(response);
        if (response.status == "success") {
          let heart = document.getElementById("a-" + serviceId);
          if (favAction == "remove") {
            heart.classList.remove("active");
          } else {
            heart.classList.add("active");
          }
        }
      },
    });
  });

  // Handles dynamic address results click for google retured addresses
  jQuery("body").on("click", ".foundAddress", function (e) {
    let parentId = jQuery(this).closest("ul").attr("id");

    if (parentId === "servicesfromResults") {
      jQuery("#servicesfrom").val(jQuery(this).text());
      jQuery("#servicesfromResults").html("");
    } else {
      jQuery("#servicesto").val(jQuery(this).text());
      jQuery("#servicestoResults").html("");
    }

    //route planner
    if(parentId === 'route-startResults'){
        e.preventDefault();
        jQuery("#route-start").val(jQuery(this).text());
        jQuery(".locationResults").html("");
    }
    if(parentId === 'route-endResults'){
        e.preventDefault();
        jQuery("#route-end").val(jQuery(this).text());
        jQuery(".locationResults").html("");
    }
    if(parentId === 'route-stopResults'){
        e.preventDefault();
        jQuery("#route-stop").val(jQuery(this).text());
        jQuery(".locationResults").html("");
    }
    //end
  });

  // Handels journey "To" and "From" address search lookup from our WP backend
  jQuery("input#brands-page-location").on("input", function () {
    let searchTerm = jQuery(this).val();
    let forBrandsPage = false;
    let brandId = jQuery("#brands-geo").attr("data-brand-id");
    getGmpaAddressIfNoServiceFound(searchTerm, jQuery(".search-widget__box__search-results"), forBrandsPage, brandId);

  });

  // Handels journey "To" and "From" address search lookup from our WP backend
  jQuery("input#start, input#end, input#route-start,  input#route-end,  input#route-stop").on("input", function () {
    let searchTerm = jQuery(this).val();
    let url = `/wp-admin/admin-ajax.php?action=gmap_get_locations`;
    let id = jQuery(this).attr("id");
    

    // Get our addresses from WP
    jQuery.ajax({
      type: "get",
      url: url,
      data: {
        term: searchTerm,
      },
      success: function (response) {
        console.log('input response', response);
        let result = JSON.parse(response);
        let html = "";

        for (let i = 0; i < result.predictions.length; i++) {
          let name = "";
          let terms = result.predictions[i].terms;

          // Last element is UK, we dont need it (terms.length -1)
          for (let j = 0; j < terms.length - 1; j++) {
            name += terms[j].value + ", ";
          }

          html += "<li><a href='#' class='foundAddress'>";
          html += name.replace(/,\s*$/, ""); // Remove last , and empty space
          html += "</a> </li>";
        }

        // Show our results
        jQuery("#" + id + "Results").html(html);
        jQuery("#" + id + "Results").show();
      },
    });
  });

  jQuery(".geo-search-cancel").click(function () {
    jQuery("#locationConfirmbox").modal("toggle");
  });
});

// Refresh our google maps after ajax call via locations filter
function gmapAddLocations(locations) {
infoWindows_arr = [];
  map = new google.maps.Map(document.getElementById("gmap"), {
    center: { lat: 53.56829377867329, lng: -2.232102155685425 },
    zoom: 6.7,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  });

  let servicesMarkerIcon =
    "/wp-content/themes/moto/images/map-marker-loaction.svg";
  let userMarkerIcon =
    "/wp-content/themes/moto/images/user-map-marker-loaction.svg";
  let marker, i;


if(locations && locations.length > 0) {
  for (i = 0; i < locations.length; i++) {
    const pos = new google.maps.LatLng(locations[i][1], locations[i][2])
    let markerIcon =
      locations[i][3] === false ? servicesMarkerIcon : userMarkerIcon;
    marker = new google.maps.Marker({
      position: pos,
      map: map,
      icon: markerIcon,
    });

    const info = new google.maps.InfoWindow({ content: locations[i][4], position: pos});
    infoWindows_arr.push(info);
    
    
    // Only need this for services
    if (locations[i][3] === false) {
    /*
      let url = locations[i][0];
      google.maps.event.addListener(marker, "click", function () {
        window.location.href = url;
      });*/
      

      marker.addListener("click", () => {
				closeAllInfoWindows()

				info.open({
					anchor: this.marker,
					map,
					shouldFocus: false,
				});
			});

    }
  }
}


}

function closeAllInfoWindows() {
	for (var i = 0; i < infoWindows_arr.length; i++) {
	console.log('infowindows closed')
		infoWindows_arr[i].close();
	}
}

function getLocationsBasedOnFilters() {
  let filtersSelected = false;

  // Get all selecetd brands
  let brandsList = [];
  jQuery.each(jQuery("input[name='brands']:checked"), function () {
    brandsList.push(jQuery(this).val());
    filtersSelected = true;
  });

  // Get all selected facilities
  let facilitiesList = [];
  jQuery.each(jQuery("input[name='facilities']:checked"), function () {
    facilitiesList.push(jQuery(this).val());
    filtersSelected = true;
  });

  // Get all selected motorways
  let motorwaysList = [];
  jQuery.each(jQuery("input[name='motorways']:checked"), function () {
    motorwaysList.push(jQuery(this).val());
    filtersSelected = true;
  });

  // Get all selected motorways
  let regionsList = [];
  jQuery.each(jQuery("input[name='regions']:checked"), function () {
    regionsList.push(jQuery(this).val());
    filtersSelected = true;
  });

  let resultsDiv = jQuery(".nearu-page__filterContent__results");
  let filtersBtn = resultsDiv.find("button");

  // If any filters selected show / hide clear button
  if (filtersSelected) {
    filtersBtn.show();
  } else {
    filtersBtn.hide();
  }

  let lat = 53.56829377867329;
  let lng = -2.232102155685425;
  let distance = 1000;
  let siteDomain = window.location.hostname;
  let url = `https://${siteDomain}/wp-json/moto/locations/`;
  url += `?lat=${lat}&lng=${lng}&per_page=100&distance=${distance}`;
  //url += `?per_page=100&distance=${distance}`;
  console.log('filtered locations data', url)
  // Get our filtered locations data
  jQuery.ajax({
    type: "get",
    url: url,
    data: {
      brand: brandsList,
      facility: facilitiesList,
      motorway: motorwaysList,
      region: regionsList,
    },
    success: function (response) {
      // HTML for locations
      let html = "";

      // Markers list for google maps
      let markers = [];

      console.log('response', response)


      for (let i = 0; i < response.length; i++) {
        html += '<div class="loactions_main__location__box">';
        html += `<a href="${response[i].link}">`;
        html += `<h5>${response[i].title.rendered}</h5>`;
        html += `<p>${response[i].acf.location.description}</p>`;
        html += "</a>";
        html += "</div>";

        let contentString = '';
          contentString += `<div id="iw-${response[i].id}" class="">`;
          contentString += '<div>';
          contentString += `<h5>${response[i].title.rendered}</h5>`;
          contentString += `<p class="mb-2">${response[i].acf.location.description}</p>`;
          if (jQuery('body').hasClass('page-template-template-careers-snu')) contentString += `<a class="btn" href="https://moto.teamtailor.com/jobs?location=${response[i].acf.id.teamtailor}" target="_blank">View details</a>`;
          if (!jQuery('body').hasClass('page-template-template-careers-snu')) contentString += `<a class="btn" href="${response[i].link}">View details</a>`;
          contentString += '</div>';
          contentString += '</div>';

        markers.push([
          response[i].link,
          response[i].acf.geo.latitude,
          response[i].acf.geo.longitude,
          false,
          contentString
        ]);
        console.log('marker');
        console.log(markers, response[i]);
      }

      // Set our html
      console.log('html', html);
      jQuery("#ajx").html(html);
      jQuery("#results-found-no").text(response.length);

      // Redraw our google map
      gmapAddLocations(markers);
    },
    error: function (xhr) {
      // If no services are found 404 is returned, clear existing display services
      if (xhr.status == 404) {
        jQuery("#ajx").html("<h3>No services found with these filters</h3>");
        jQuery("#results-found-no").text("0");
      }
    },
  });
}

function servicesMainPageLoadServices(lat, lng, searchTerm, brand) {
  let distance = 1000;
  let siteDomain = window.location.hostname;
  let url = `https://${siteDomain}/wp-json/moto/locations?`;
  let resultsDiv = jQuery(".nearu-page__filterContent__results");
  let perpage = 100;

  if (searchTerm) {
    url += `search=${searchTerm}&lng=${lng}&`;
    perpage = 20;
  }

  if (lat && lng) {
    url += `lat=${lat}&lng=${lng}&`;
    perpage = 20;
  }

  if (brand) {
    url += `brand[]=${brand}&`;
  }

  url += `per_page=${perpage}&distance=${distance}`;
  //console.log(url);

  // Get our filtered locations data
  jQuery.ajax({
    type: "get",
    url: url,
    data: {},
    success: function (response) {
      // HTML for locations
      let html = "";

      // Markers list for google maps
      let markers = [];

      // Add if user location is available
      if (lat && lng) {
        let itsUserLocation = true;
        markers.push([false, lat, lng, itsUserLocation]);
      }

      for (let i = 0; i < response.length; i++) {
        let decription =
          response[i].acf.hasOwnProperty("location") == false
            ? ""
            : response[i].acf.location.description;
        let lat =
          response[i].acf.hasOwnProperty("geo") == false
            ? ""
            : response[i].acf.geo.latitude;
        let lng =
          response[i].acf.hasOwnProperty("geo") == false
            ? ""
            : response[i].acf.geo.longitude;

        /** If invalid services dont add to list */
        if (lat && lng && decription) {
          html += '<div class="loactions_main__location__box">';
          if (jQuery('body').hasClass('page-template-template-careers-snu')) html += `<a href="https://moto.teamtailor.com/jobs?location=${response[i].acf.id.teamtailor}" target="_blank">`;
          if (!jQuery('body').hasClass('page-template-template-careers-snu')) html += `<a href="${response[i].link}">`;
          //html += `<a href="${response[i].link}">`;
          html += `<h5>${response[i].title.rendered}</h5>`;
          html += `<p>${decription}</p>`;
          html += "</a>";
          html += "</div>";

          let contentString = '';
          contentString += `<div id="iw-${response[i].id}" class="">`;
          contentString += '<div>';
          contentString += `<h5>${response[i].title.rendered}</h5>`;
          contentString += `<p class="mb-2">${decription}</p>`;
          if (jQuery('body').hasClass('page-template-template-careers-snu')) contentString += `<a class="btn" href="https://moto.teamtailor.com/jobs?location=${response[i].acf.id.teamtailor}" target="_blank">View details</a>`;
          if (!jQuery('body').hasClass('page-template-template-careers-snu')) contentString += `<a class="btn" href="${response[i].link}">View details</a>`;
          contentString += '</div>';
          contentString += '</div>';

          markers.push([response[i].link, lat, lng, false, contentString]);
        }
      }

      // Set our html
      jQuery("#ajx").html(html);
      jQuery("#results-found-no").text(response.length);

      // Redraw our google map
      gmapAddLocations(markers);
    },
    error: function (xhr) {
      // If no services are found 404 is returned, clear existing display services
      if (xhr.status == 404) {
        jQuery("#ajx").html("<h3>No services found with these filters</h3>");
        jQuery("#results-found-no").text("0");
      }
    },
  });
}

function getLocationsBySearch(searchTerm, inputID) {
  let distance = 1000;
  let siteDomain = window.location.hostname;
  let resultsDiv =
    inputID === "location"
      ? jQuery(".search-results")
      : jQuery(".search-widget__box__search-results");

  resultsDiv.html("");

  let url = `https://${siteDomain}/wp-json/moto/nogeosearch/`;
  url += `?search=${searchTerm}`;
  url += `&per_page=100&distance=${distance}`;
  // Get our filtered locations data
  jQuery.ajax({
    type: "get",
    url: url,
    success: function (response) {
      //alert(response.length);
      // Construct results html
      let html = "<ul>";
      for (let i = 0; i < response.length; i++) {
        html += `<li><a href="/services/${response[i].post_name}">${response[i].post_title}</a></li>`;
      }
      html += "</ul>";

      // Render html to results container
      resultsDiv.html(html);
    },
    error: function (xhr) {
      // If no services are found 404 is returned, clear displayed services
      if (xhr.status == 404) {
        resultsDiv.html("");
        //No location was found, do geo search

        let forBrandsPage = false;
        let brandID = false;

        getGmpaAddressIfNoServiceFound(searchTerm, resultsDiv, forBrandsPage, brandID);
      }
    },
  });
}

// Gets journey "Start" lookup from our WP backend
function getGmpaAddressIfNoServiceFound(searchTerm, resultsDivToPopulate, forBrandPage, brandId) {
  let url = `/wp-admin/admin-ajax.php?action=gmap_get_locations`;
  // Get our addresses from WP
  jQuery.ajax({
    type: "get",
    url: url,
    data: {
      term: searchTerm,
    },
    success: function (response) {
      let result = JSON.parse(response);
      let html = "<ul>";

      for (let i = 0; i < result.predictions.length; i++) {
        let name = "";
        let terms = result.predictions[i].terms;

        // Last element is UK, we dont need it (terms.length -1)
        for (let j = 0; j < terms.length - 1; j++) {
          name += terms[j].value + ", ";
        }
        name = name.replace(/,\s*$/, ""); // Remove last , and empty space

        // place_id for google geocode lookup on click
        const placeId = result.predictions[i].place_id;

        //console.log(placeId);

        // html += `<li><a href='/route-planner/?start=${encodeURIComponent(name)}'>`;
        html += `<li><span class="search-services__result-item" data-brandid="${brandId}" data-forBrandPage="${forBrandPage}" data-g-place-id="${placeId}">`;
        html += name;
        html += "</span> </li>";
        html += "</a> </li>";
      }

      html += "</ul>";

      // Show our results
      resultsDivToPopulate.html(html);
      resultsDivToPopulate.show();
    },
  });
}
